@import ../../../styles/helpers

.hero
    position: relative
    display: flex
    align-items: center
    min-height: 880px
    padding: 34px 0 130px
    +x
        min-height: 800px
    +d
        min-height: 624px
    +m
        min-height: auto
        padding: 64px 0 152px

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 340px
    +m
        max-width: 100%
        margin-bottom: 80px

.title
    margin-bottom: 20px

.text
    margin-bottom: 40px
    color: $neutrals4

.btns
    display: flex
    +m
        display: block

.button
    +m
        width: 100%
    &:not(:last-child)
        margin-right: 16px
        +m
            margin: 0 0 16px
    
.scroll
    position: absolute
    bottom: 96px
    +d
        bottom: 48px
    +m
        bottom: 64px

.gallery
    position: absolute
    top: 50%
    right: calc(50% - 521px)
    width: 682px
    transform: translateY(-58%)
    pointer-events: none
    +x
        right: calc(50% - 425px)
        width: 450px
    +d
        right: calc(50% - 365px)
    +t
        right: calc(50% - 300px)
    +m
        position: relative
        top: auto
        right: auto
        left: -45px
        margin: 0 auto
        width: 100%
        transform: translate(0,0)
        pointer-events: all

.preview
    &:first-child
        position: relative
        z-index: 2
        width: 100%
        left: 80px
        +m
            top: 0px
            width: 140%
            left: -9%
    &:nth-child(2)
        top: 47%
        right: -13%
        z-index: 3
        width: 35%
        +m
            top: 37%
            right: -23%
    &:nth-child(3)
        top: -5%
        right: -2%
        z-index: 1
        width: 39%
    &:nth-child(4)
        left: 11%
        bottom: 9%
        z-index: 3
        width: 28%
        +m
            bottom: 14%
    &:not(:first-child)
        position: absolute
    img
        width: 100%