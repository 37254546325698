@import ../../../styles/helpers

.section
    padding-top: 80px

.row
    display: flex
    align-items: flex-start
    +t
        display: block

.col
    &:first-child
        flex: 0 0 calc(100% - 352px)
        max-width: calc(100% - 352px)
        padding-right: 96px
        +d
            flex: 0 0 calc(100% - 305px)
            max-width: calc(100% - 305px)
            padding-right: 32px
        +t
            max-width: 100%
            margin-bottom: 48px
            padding: 0
        +m
            margin-bottom: 32px
    &:nth-child(2)
        flex: 0 0 352px
        max-width: 352px
        +d
            flex: 0 0 305px
            max-width: 305px
        +t
            max-width: 100%

.details
    display: flex
    margin-bottom: 16px

.status
    &:not(:last-child)
        margin-right: 4px

.title
    margin-bottom: 8px

.info
    margin-bottom: 48px
    +body-1
    color: $neutrals4
    +m
        margin-bottom: 24px
        font-size: 16px

.wrap
    margin: 0 -10px

.preview
    position: relative
    img
        width: 100%
        border-radius: 16px

.item
    display: flex
    +caption-1
    font-weight: 500
    &:not(:last-child)
        margin-bottom: 20px

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 20px
    height: 24px
    margin-right: 12px
    svg
        fill: $neutrals4

.parameter
    flex: 0 0 156px
    padding-right: 16px
    color: $neutrals4

.text
    margin-top: 40px
    padding-top: 40px
    border-top: 1px solid $neutrals6
    color: $neutrals3
    +d
        margin-top: 32px
        padding-top: 32px
    +dark
        border-color: $neutrals3
        color: $neutrals6
    p
        &:not(:last-child)
            margin-bottom: 24px

.list
    margin-top: 40px
    padding: 24px 20px
    border-radius: 8px
    border: 2px solid $neutrals6
    +d
        margin-top: 32px
    +dark
        border-color: $neutrals3

.box
    position: relative
    padding-left: 26px
    font-weight: 600
    &:before
        content: ""
        position: absolute
        top: 2px
        left: 0
        width: 16px
        height: 16px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath fill-rule='evenodd' d='M8.999 17.333c4.602 0 8.333-3.731 8.333-8.333S13.602.667 8.999.667.666 4.398.666 9s3.731 8.333 8.333 8.333zm4.874-10.126a1 1 0 0 0-1.414-1.414l-4.293 4.293-1.793-1.793a1 1 0 1 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l5-5z' fill='%2345b36b'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    &:not(:last-child)
        margin-bottom: 8px

.btns
    display: flex
    margin-top: 48px
    +d
        margin-top: 40px

    .button
        &:first-child
            margin-right: 12px
        &:nth-child(2)
            flex-grow: 1
