@import ../../styles/helpers

.title
    margin-bottom: 40px
    text-align: center
    font-weight: 600

.list
    display: flex
    margin: 0 -12px
    +d
        margin: 0 -8px
    +m
        flex-wrap: wrap
        margin-left: -20px
        overflow: auto
        overflow-x: auto 
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none 

.logo
    flex: 1
    display: flex
    margin: 0 12px
    max-height: 64px
    +d
        margin: 0 8px
    +m
        flex: 0 0 140px
        margin: 16px
        min-height: 64px
    img
        width: 100%
           