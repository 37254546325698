@import ../../styles/helpers

.section
    position: relative
    display: flex
    align-items: center
    min-height: 816px
    padding: 100px 0
    +d
        min-height: 600px
        padding: 80px 0
    +m
        display: block
        min-height: auto
        padding: 32px 0 64px

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 360px

.title
    margin-bottom: 32px

.text
    margin-bottom: 40px
    +body-1
    color: $neutrals4

.gallery
    position: absolute
    top: 43%
    right: calc(50% - 702px)
    width: 732px
    transform: translateY(-40%)
    pointer-events: none
    +d
        right: calc(50% - 440px)
        width: 390px
        transform: translateY(-44%)
    +t
        right: calc(40% - 380px)
    +m
        position: relative
        top: auto
        left: 58%
        width: 459px
        transform: translate(-50%,0)

.preview
    &:first-child
        position: relative
        z-index: 2
        left: -90px
        +m
            left: -40px
            width: 100%
            top: -50px
    &:nth-child(2)
        top: 63%
        left: 4%
        z-index: 1
        width: 33%
        +m
            top: 64%
            left: 4%
            width: 28%
    &:nth-child(3)
        top: -5%
        right: 23%
        z-index: 1
        width: 33%
        +m
            top: 0%
            right: 23%
            width: 27%
    &:not(:first-child)
        position: absolute
    img
        width: 100%