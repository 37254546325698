@import ../../styles/helpers


.section
    padding-top: 0px
    +d
        padding: 112px 0
    +m
        padding: 64px 0


.head
    margin-bottom: 80px
    text-align: center
    +m
        margin-bottom: 0px

.title
    margin-bottom: 20px

.info
    max-width: 455px
    margin: 0 auto
    color: $neutrals3
    +d
        max-width: 100%
        color: $neutrals4
    +dark
        color: $neutrals5

.list
    display: flex
    margin: 0 -12px
    +t
        margin: 0px
        flex-wrap: wrap
        overflow-y: clip
        overflow-x: auto 
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none 
        &:before,
        &:after
            content: undefined
            flex-shrink: 0
            width: 0px
            height: 0px

.item
    flex: 0 0 calc(33.333%)
    width: calc(33.333% - 24px)
    margin: 0 12px
    padding: 0 32px
    text-align: center
    +t
        flex: 0 0 280px
        margin: 0 auto
        padding: 0
    +m
        flex: 0 0 260px

.preview
    height: 308px
    margin-bottom: -87px
    img
        max-height: 100%

.subtitle
    +body-2
    +m
        font-size: 16px
    