@import ../../styles/helpers

.form
    position: relative

.input
    width: 100%
    height: 48px
    padding: 0 14px 0 14px
    margin: 0 0 16px 0
    border-radius: 8px
    background: none
    border: 2px solid $neutrals6
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    &:focus
        border-color: $neutrals4
    +placeholder
        color: $neutrals4

.button
    margin-bottom: 16px
    +m
        width: 100%
    
// .btn
//     left: 8px
//     margin-top: 16px
//     bottom: 8px
//     width: 32px
//     height: 32px
//     border-radius: 50%
//     background: $accentColor
//     transition: background .2s
//     svg
//         fill: $neutrals8
//     &:hover
//         background: darken($accentColor, 10)